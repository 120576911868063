import { GridColDef } from '@mui/x-data-grid';

import { buildColumn } from '../../helpers/tables';
import {
  ENROLLMENT_STATE_ENROLLED,
  ENROLLMENT_STATE_INACTIVE,
  ENROLLMENT_STATE_WAIT,
  ENROLLMENT_STATES_DICTIONARY,
} from '../../common/constants';
import { EnrollmentInterface, RowInterface } from '../../interfaces/enrollment';

const optionsFilter = [
  {
    key: 'state',
    label: ENROLLMENT_STATES_DICTIONARY.enrolled,
    value: ENROLLMENT_STATE_ENROLLED,
  },
  {
    key: 'state',
    label: ENROLLMENT_STATES_DICTIONARY.inactive,
    value: ENROLLMENT_STATE_INACTIVE,
  },
  {
    key: 'state',
    label: ENROLLMENT_STATES_DICTIONARY.wait,
    value: ENROLLMENT_STATE_WAIT,
  },
];

const columns: GridColDef[] = [
  buildColumn({
    field: 'studentName',
    headerName: 'Nombre y Apellido',
    minWidth: 150,
    flex: 1,
    cellAlign: true,
    renderCellSlice: 32,
  }),
  buildColumn({
    field: 'document',
    headerName: 'Tipo y N° de Documento',
    width: 180,
  }),
  buildColumn({
    field: 'internalCourseId',
    headerName: 'ID Interno',
  }),
  buildColumn({
    field: 'courseEnrolled',
    headerName: 'Curso al que se inscribió',
    minWidth: 150,
    flex: 1,
    cellAlign: true,
    renderCellSlice: 32,
  }),
  buildColumn({
    field: 'startInscriptionDate',
    headerName: 'Fecha de inscripción',
    width: 150,
  }),
  buildColumn({
    field: 'enrollmentStatus',
    headerName: 'Estado de inscripción',
    width: 150,
  }),
];

const buildRows = (rows: EnrollmentInterface[]): RowInterface[] => rows?.map(({
  _id,
  user,
  course,
  lastState,
}) => ({
  courseEnrolled: course.name,
  // eslint-disable-next-line no-underscore-dangle
  courseId: course._id,
  document: user.documentType.toUpperCase().concat(' ', user.document),
  enrollmentStatus: ENROLLMENT_STATES_DICTIONARY[lastState.type],
  id: _id,
  internalCourseId: course.internalCourseId,
  removeEnrollment: 'DAR DE BAJA',
  seeDetails: 'VER',
  startInscriptionDate: lastState.date,
  studentId: user.id,
  studentName: user.first_name.concat(' ', user.last_name),
}));

export {
  buildRows,
  columns,
  optionsFilter,
};
