import {
  QUALIFICATION_APPROVED,
  QUALIFICATION_FINALIZED,
  QUALIFICATION_IN_PROGRESS,
  QUALIFICATION_PENDING,
  QUALIFICATIONS_DICTIONARY,
} from '../../../common/constants';
import { OptionInterface } from '../../../interfaces/inputs';

export const qualifications: OptionInterface[] = [
  { id: QUALIFICATION_PENDING, name: QUALIFICATIONS_DICTIONARY[QUALIFICATION_PENDING] },
  { id: QUALIFICATION_IN_PROGRESS, name: QUALIFICATIONS_DICTIONARY[QUALIFICATION_IN_PROGRESS] },
  { id: QUALIFICATION_FINALIZED, name: QUALIFICATIONS_DICTIONARY[QUALIFICATION_FINALIZED] },
  { id: QUALIFICATION_APPROVED, name: QUALIFICATIONS_DICTIONARY[QUALIFICATION_APPROVED] },
];

export const findQualification = (name: string) => qualifications
  .find((qualification) => qualification.name === name);
