import { ENROLLMENT_STATE_ENROLLED, ENROLLMENT_STATE_INACTIVE, ENROLLMENT_STATES_DICTIONARY } from '../../../common/constants';

import { DiscountTypes, EnrollmentInterface } from '../../../interfaces/enrollment';

const buildModalData = (enrollment: EnrollmentInterface) => {
  const isInactive = enrollment.lastState.type === ENROLLMENT_STATE_INACTIVE;

  return [{
    label: 'Nombre y apellido:',
    value: `${enrollment.user.first_name} ${enrollment.user.last_name}`,
  },
  {
    label: 'Documento:',
    value: enrollment.user.document,
  },
  {
    label: 'Email:',
    value: enrollment.user.email,
  },
  {
    label: 'Curso al que se inscribió:',
    value: enrollment.course.name,
  },
  {
    label: 'Fecha de inscripción:',
    value: enrollment.historyStates.reduce((last, current) => (current.type === ENROLLMENT_STATE_ENROLLED ? current : last), null)?.date ?? 'No tiene',
  },
  ...(isInactive
    ? [{
      label: 'Fecha de baja:',
      value: enrollment.lastState.date,
    }]
    : []),
  {
    label: 'Categoría de usuario:',
    value: enrollment.userCategory || 'No tiene',
  },
  {
    label: 'Descuento:',
    value: enrollment.discountType === DiscountTypes.Fixed ? `$${enrollment.discount || 0}` : `${enrollment.discount || 0}%`,
  },
  {
    label: 'Estado de inscripción:',
    value: ENROLLMENT_STATES_DICTIONARY[enrollment.lastState.type],
  },
  ];
};

export {
  // eslint-disable-next-line import/prefer-default-export
  buildModalData,
};
