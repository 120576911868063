const START_PAGE_SIZE = 1;
const QUERY_LIMIT = 15;

const MAX_LOGOS = 2;

const ENROLLMENT_STATE_CANCELLED = 'cancelled';
const ENROLLMENT_STATE_ENROLLED = 'enrolled';
const ENROLLMENT_STATE_INACTIVE = 'inactive';
const ENROLLMENT_STATE_WAIT = 'wait';

const ENROLLMENT_STATES_DICTIONARY = {
  [ENROLLMENT_STATE_CANCELLED]: 'Cancelado',
  [ENROLLMENT_STATE_ENROLLED]: 'Confirmado',
  [ENROLLMENT_STATE_INACTIVE]: 'Inactivo',
  [ENROLLMENT_STATE_WAIT]: 'Lista de espera',
};

export const QUALIFICATION_PENDING = 'pending';
export const QUALIFICATION_IN_PROGRESS = 'inProgress';
export const QUALIFICATION_FINALIZED = 'finalized';
export const QUALIFICATION_APPROVED = 'approved';

export const QUALIFICATIONS_DICTIONARY = {
  [QUALIFICATION_PENDING]: 'Calificación pendiente',
  [QUALIFICATION_IN_PROGRESS]: 'No finalizado',
  [QUALIFICATION_FINALIZED]: 'Finalizado y no aprobado',
  [QUALIFICATION_APPROVED]: 'Finalizado y aprobado',
};

const SCOPES = {
  canAccessVirtualCampus: 'can-access-virtual-campus',
  canCreateCourses: 'can-create-courses',
  canCreateEvaluations: 'can-create-evaluations',
  canCreateInscription: 'can-create-inscription',
  canCreateLogo: 'can-create-logo',
  canCreateUser: 'can-create-user',
  canEditCourses: 'can-edit-courses',
  canRemoveCourses: 'can-remove-courses',
  canRemoveLogo: 'can-remove-logo',
  canSeeAcademicOffer: 'can-see-academic-offer',
  canSeeCourseList: 'can-see-course-list',
  canSeeEvaluationsList: 'can-see-evaluations-list',
  canSeeUsersList: 'can-see-users-list',
  canViewInscriptionsList: 'can-view-inscriptions-list',
  canViewOwnCertificates: 'can-view-own-certificates',
  canViewOwnCourses: 'can-view-own-courses',
  canViewOwnEvaluations: 'can-view-own-evaluations',
  canViewOwnPayments: 'can-view-own-payments',
  canViewPaymentsList: 'can-view-payments-list',
};

export {
  ENROLLMENT_STATE_ENROLLED,
  ENROLLMENT_STATE_INACTIVE,
  ENROLLMENT_STATE_WAIT,
  ENROLLMENT_STATES_DICTIONARY,
  MAX_LOGOS,
  QUERY_LIMIT,
  SCOPES,
  START_PAGE_SIZE,
};
