import { ItemFilter } from './types';

const manipulateFilterString = (query: any, filter: ItemFilter): string => {
  const newArray: string[] = query[filter.key] ? query[filter.key].split(',') : [];
  if (filter.valueOff) {
    return filter.checked ? filter.value as string : filter.valueOff;
  }

  if (filter.checked) {
    newArray.push(filter.value as string);
    const arrayWithValuesRepeated = [...new Set(newArray)];
    return arrayWithValuesRepeated.join(',');
  }

  const arrayWithValuesRepeated = [...new Set(newArray)];
  return arrayWithValuesRepeated.filter((itemArray) => itemArray !== filter.value).join(',');
};

export default manipulateFilterString;
