import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';

import manipulateFilterString from './constants';
import { FiltersButtonProps } from './types';

import classes from './classes';

const FilterButton = ({ setQuery, query, options }: FiltersButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filters, setFilters] = useState(options.map((option) => ({ ...option, checked: false })));

  const openFilters = Boolean(anchorEl);

  const handleOnClickFilters = (event) => setAnchorEl(event.currentTarget);
  const handleOnCloseFilters = () => setAnchorEl(null);

  const changeStateCheckbox = (indexCheckbox: number) => {
    setFilters((oldFilters) => oldFilters.map(
      (filter, indexFilter) => ({
        ...filter,
        checked: indexFilter === indexCheckbox ? !filter.checked : filter.checked,
      }),
    ));
  };

  useEffect(() => {
    const newQuery = query;
    filters.forEach((filter) => {
      if (typeof filter.value === 'string') {
        const newValue = manipulateFilterString(newQuery, filter);
        newQuery[filter.key] = newValue || undefined;
      } else {
        newQuery[filter.key] = filter.checked ? filter.value : undefined;
      }
    });
    setQuery((oldQuery) => ({ ...oldQuery, ...newQuery }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <Button
        id="filter-courses"
        aria-controls={openFilters ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openFilters ? 'true' : undefined}
        variant="outlined"
        endIcon={<FilterListIcon />}
        onClick={handleOnClickFilters}
        sx={classes.filtersButton}
      >
        FILTRAR POR
      </Button>
      <Menu
        id="filter-courses"
        anchorEl={anchorEl}
        open={openFilters}
        onClose={handleOnCloseFilters}
      >
        {filters.map((filter, index) => (
          <MenuItem key={filter.label} onClick={() => changeStateCheckbox(index)}>
            <Checkbox checked={filter.checked} />
            <Typography>{filter.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default React.memo(FilterButton);
