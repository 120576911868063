const classes = {
  filtersButton: {
    fontSize: '11px',
    fontWeight: 600,
    gap: 0,
    lineHeight: '10px',
    minWidth: '135px',
    width: '135px',
  },
};

export default classes;
