import React, { useState, useEffect, useMemo } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import LoadingButton from '../../../components/Controls/LoadingButton';
import PendingPaymentsModal from './PendingPaymentsModal';
import { QUALIFICATION_APPROVED, QUALIFICATION_PENDING } from '../../../common/constants';
import { findQualification, qualifications } from './constants';
import { OptionInterface } from '../../../interfaces/inputs';
import { InstallmentStatuses } from '../../../interfaces/payments';
import { EditQualificationModalProps } from './types';

import classes from './classes';

const EditQualificationModal = ({
  enrollment,
  openModal,
  onClickButton,
  onCloseModal,
}: EditQualificationModalProps) => {
  const [qualification, setQualification] = useState<string>(enrollment?.qualification || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPendingPaymentsModalOpen, setIsPendingPaymentsModalOpen] = useState<boolean>(false);

  const isThereErrorInQualificationInput = useMemo(() => qualification === '' || qualification === undefined, [qualification]);

  const isQualificationPending = qualification === QUALIFICATION_PENDING;

  const handleOnClick = async () => {
    setIsLoading(true);

    const newQualification = findQualification(qualification)?.id;
    const hasUnapprovedInstallments = enrollment.payment.installmentsData.some(
      ({ status }) => status !== InstallmentStatuses.Approved,
    );
    const shouldApproveQualification = hasUnapprovedInstallments
      && newQualification === QUALIFICATION_APPROVED;

    if (shouldApproveQualification) {
      setIsPendingPaymentsModalOpen(true);
      setIsLoading(false);
      return;
    }

    await onClickButton(newQualification);
    setIsLoading(false);
  };

  const handleConfirm = async (): Promise<void> => {
    setIsPendingPaymentsModalOpen(false);
    setIsLoading(true);

    const newQualification = findQualification(qualification)?.id;
    await onClickButton(newQualification);

    setIsLoading(false);
  };

  useEffect(() => {
    setQualification(enrollment?.qualification);
  }, [enrollment?.qualification]);

  return (
    <>
      <Modal open={openModal} onClose={onCloseModal}>
        <Box sx={classes.modalContainer}>
          <Box sx={classes.closeButtonContainer}>
            <IconButton size="large" onClick={onCloseModal} sx={classes.closeIconButton}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={classes.modalContentContainer}>
            <Typography variant="h2" color="primary.main" sx={classes.modalTitle}>
              {isQualificationPending ? 'Calificar alumno/a' : 'Editar calificación'}
            </Typography>
            <Typography variant="h5">
              Seleccioná una calificación
            </Typography>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Typography variant="h5" color="secondary.main">
                Alumno/a:
              </Typography>
              <Typography variant="h5">
                {enrollment?.studentName}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Typography variant="h5" color="secondary.main">
                Documento:
              </Typography>
              <Typography variant="h5">
                {enrollment?.document.split(' ')[1]}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              <Typography variant="h5" color="secondary.main">
                Curso al que se inscribió:
              </Typography>
              <Typography variant="h5">
                {enrollment?.courseEnrolled}
              </Typography>
            </Box>

            <Autocomplete
              disablePortal
              value={findQualification(qualification) || ''}
              onChange={(e, v: OptionInterface) => setQualification(v?.name || '')}
              options={qualifications}
              onKeyDown={(e) => e.stopPropagation()}
              getOptionLabel={(option: OptionInterface) => option.name || ''}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  error={isThereErrorInQualificationInput}
                  helperText={isThereErrorInQualificationInput && 'Calificación es requerida'}
                  label="Calificación"
                  variant="standard"
                />
              )}
            />
            <Grid container sx={classes.modalButtonContainer}>
              <Grid item flexGrow={1}>
                <Button variant="outlined" fullWidth onClick={onCloseModal}>
                  Volver
                </Button>
              </Grid>
              <Grid item flexGrow={1}>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  fullWidth
                  onClick={handleOnClick}
                >
                  {isQualificationPending ? 'Aceptar' : 'Guardar'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <PendingPaymentsModal
        openModal={isPendingPaymentsModalOpen}
        onClick={handleConfirm}
        onCloseModal={() => setIsPendingPaymentsModalOpen(false)}
      />
    </>
  );
};

export default React.memo(EditQualificationModal);
