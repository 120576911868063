import { SxProps, Theme } from '@mui/material';

const classes: Record<string, SxProps<Theme>> = {
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: 2,
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: '0px',
    maxWidth: '502px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    gap: 2,
  },
  modalContentContainer: {
    pt: 1,
    px: 4,
    pb: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    gap: 2,
  },
  modalTitle: {
    fontWeight: 600,
  },
  modalButtonContainer: {
    mt: 1,
    width: '100%',
    gap: { xs: 2, sm: 4 },
  },
  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  closeIconButton: {
    padding: 0,
    color: '#909BD7',
  },
};

export default classes;
